import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  formatSaveData,
} from 'utils/formStyles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PlaceLookup from 'components/PlaceLookup';
import Autocomplete2 from 'components/Autocomplete2';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { getAgency, editAgency, addAgency } from 'Settings/Agencies/AgencyFunctions';
import FormDivider from 'components/FormDivider';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getService } from 'reducers/service';
import { Fab, Grid, Menu, Typography } from '@material-ui/core';
import options from 'components/IconsAgency';
import { showEditAgency, closeEditAgency } from 'reducers/DialogsReducer';
import ImageUpload from './imageUpload';
const useStyles = makeStyles(theme => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));
const ITEM_HEIGHT = 48;

function AgencyTab(props) {
  const classes = useStyles();
  const {
    agencyId,
    setAgencyPlaceDepartmentId,
    dictionary,
    mode,
    closeEditAgency,
    showEditAgency,
  } = props;
  const { PlaceTypes, codeIncidentReportVersions } = dictionary;
  const [AgencyID, setAgencyID] = useState();
  const [AgencyType, setAgencyType] = useState(0);
  const [Description, setDescription] = useState('');
  const [Place, setPlace] = useState(null);
  const [PlaceName, setPlaceName] = useState('');
  const [PlaceORI, setPlaceORI] = useState('');
  const [PlaceDepartmentID, setPlaceDepartmentID] = useState('');
  const [PlaceType, setPlaceType] = useState(null);
  const [IncidentReportVersion, setIncidentReportVersion] = useState(null);
  const [isPolice, setIsPolice] = useState(false);
  const [DefaultCustodyRecord, setDefaultCustodyRecord] = useState(false);
  const [isEms, setIsEms] = useState(false);
  const [isFire, setIsFire] = useState(false);
  const [ptsPlaceID, setPtsPlaceID] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState(null);
  const [imageData, setImageData] = useState({
    fileName: '',
    fileType: '',
  });
  const [newImg, setNewImg] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const search = async () => {
      const data = await getAgency(agencyId);
      initData(data);
    };
    if (mode === 'edit') {
      search();
    }
  }, []);

  useEffect(() => {
    const getAttachment = async () => {
      const service = getService('settings-agency-badge-upload');
      const attachment = await service.find({
        query: {
          ParentType: AgencyID,
          ptsParentID: AgencyType,
        },
      });

      if (attachment.length > 0) {
        const { ptsAttachmentID, FileType, FileName } = attachment[0];
        const img = await service.get(ptsAttachmentID);

        setImageData({ fileName: FileName, fileType: FileType });
        setImage(img[0].Image ? `data:image/jpeg;base64,${img[0].Image}` : null);
      }
    };

    if (AgencyID && mode === 'edit') {
      getAttachment();
    }
  }, [AgencyID, AgencyType]);

  const updateFireRmsInterface = async () => {
    const service = getService('settings-agency-cad');
    const obj = [
      {
        Path: `${agencyId}.CAD.Event.FireHouseEnabled`,
        CDRKey: 'CAD Event',
        ValueBoolean: false,
      },
      {
        Path: `${agencyId}.CAD.Event.FireHouseExportOnEventClose`,
        CDRKey: 'CAD Event',
        ValueBoolean: false,
      },
    ];
    try {
      await service.create(obj);
    } catch (err) {
      props.handleError(err, 'Error Saving Agency CAD Settings.');
    }
  };
  const passPlaceDepartmentId = placeDepartmentId => setAgencyPlaceDepartmentId(placeDepartmentId);

  const setPlaceDetails = async data => {
    const { PlaceDepartmentID, ptsPlaceID, PlaceName, PlaceORI, PlaceType } = data || {};
    setPtsPlaceID(ptsPlaceID);
    setPlaceName(PlaceName || '');
    setPlaceORI(PlaceORI || '');
    setPlaceType(PlaceTypes?.find(type => type.Code === PlaceType) || null);
    setPlaceDepartmentID(PlaceDepartmentID || '');
    /* pass placeDepartmentID to AgencyCad for enabling/disabling FIRE RMS INTERFACE*/
    passPlaceDepartmentId(PlaceDepartmentID);
    if (!PlaceDepartmentID) await updateFireRmsInterface();
  };
  const initData = async data => {
    const {
      Description,
      EMS,
      Fire,
      AgencyType,
      Police,
      Icon,
      IncidentReportVersion,
      DefaultCustodyRecord,
    } = data;

    setAgencyID(agencyId);
    setAgencyType(AgencyType || 0);
    setSelectedIconIndex(Icon || 0);
    setDescription(Description || '');
    setIncidentReportVersion(
      codeIncidentReportVersions?.find(type => type.Code === IncidentReportVersion) || null
    );

    setIsPolice(Police === 1);
    setIsEms(EMS === 1);
    setIsFire(Fire === 1);
    setPlaceDetails(data);
    setDefaultCustodyRecord(DefaultCustodyRecord === 1);
  };

  const handleSaveClick = async () => {
    const saveObj = formatSaveData({
      Description,
      PlaceName,
      PlaceORI,
      PlaceType,
      IncidentReportVersion,
      DefaultCustodyRecord,
      PlaceDepartmentID,
      Icon: selectedIconIndex,
    });
    saveObj.AgencyType = calAgencyType();
    saveObj.ptsPlaceID = Place?.ptsPlaceID || null;
    try {
      if (mode === 'edit') {
        await editAgency(agencyId, saveObj);
        if (image && newImg) {
          const serviceImage = getService('settings-agency-badge-upload');
          await serviceImage.create({
            parentType: AgencyID,
            ptsParentID: AgencyType,
            data: image.split(',')[1],
            fileName: imageData.fileName,
            fileType: imageData.fileType,
          });
        }
        /* pass placeDepartmentID to AgencyCad for enabling/disabling FIRE RMS INTERFACE*/
        passPlaceDepartmentId(PlaceDepartmentID);
        if (!PlaceDepartmentID) await updateFireRmsInterface();
        props.notifyDataUpdate({ type: 'agencies' });
        props.notify('Agency Updated!', 'success');
      } else {
        const data = await addAgency({ ...saveObj, AgencyID });
        if (image && newImg) {
          const serviceImage = getService('settings-agency-badge-upload');
          await serviceImage.create({
            parentType: AgencyID,
            ptsParentID: AgencyType,
            data: image.split(',')[1],
            fileName: imageData.fileName,
            fileType: imageData.fileType,
          });
        }
        /* pass placeDepartmentID to AgencyCad for enabling/disabling FIRE RMS INTERFACE*/
        passPlaceDepartmentId(PlaceDepartmentID);
        if (!PlaceDepartmentID) await updateFireRmsInterface();
        props.notifyDataUpdate({ type: 'agencies' });
        props.notify('Agency Created!', 'success');
        closeEditAgency(null);

        showEditAgency({ AgencyID: data.AgencyID, Description: data.Description, mode: 'edit' });
      }
    } catch (err) {
      if (mode === 'add') {
        if (err.message === 'SequelizeUniqueConstraintError: Validation error') {
          props.notify('AgencyID Must Be Unique!', 'error');
        } else {
          props.handleError(err, 'Error Creating Agency');
        }
      } else {
        props.handleError(err, 'Error Updating Agency');
      }
    }
  };
  // const handleSaveAddClick = async () => {
  //   const saveObj = {
  //     Description,
  //     AgencyID,
  //     AgencyType: calAgencyType(),
  //     CreatedBy: userName,
  //     Icon: selectedIconIndex,
  //   };
  //   saveObj.AgencyType = calAgencyType();

  //   try {
  //     const data = await addAgency(saveObj);
  //     /* pass placeDepartmentID to AgencyCad for enabling/disabling FIRE RMS INTERFACE*/

  //     showEditAgency({ data: data.AgencyID, mode: 'edit' });
  //     props.notify('Agency Created!', 'success');
  //   } catch (err) {
  //     props.handleError(err, 'Error Updating Agency');
  //   }
  // };
  const calAgencyType = () => {
    let out = 0;
    if (isPolice) out += 1;
    if (isFire) out += 2;
    if (isEms) out += 4;
    return out;
  };

  const renderAgencyID = () => {
    return (
      <TextField
        label="Agency ID"
        variant="outlined"
        value={AgencyID || ''}
        size="small"
        className={classes.item}
        placeholder="Max Length 10"
        inputProps={{ maxLength: 10 }}
        onChange={e => setAgencyID(e.target.value)}
        disabled={mode === 'edit'}
      />
    );
  };

  const renderDescription = () => {
    return (
      <TextField
        label="Description"
        variant="outlined"
        onChange={e => setDescription(e.target.value)}
        value={Description}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderPlaceName = () => {
    return (
      <TextField
        label="Place Name"
        variant="outlined"
        onChange={e => setPlaceName(e.target.value)}
        value={PlaceName}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderPlaceOri = () => {
    return (
      <TextField
        label="Place ORI"
        variant="outlined"
        onChange={e => setPlaceORI(e.target.value)}
        value={PlaceORI}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderPlaceDeptID = () => {
    return (
      <TextField
        label="Place Department ID"
        variant="outlined"
        onChange={e => setPlaceDepartmentID(e.target.value)}
        value={PlaceDepartmentID}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderPlace = () => {
    const handleChange = value => {
      setPlaceDetails(value);
      setPlace(value);
    };
    return (
      <PlaceLookup
        onChange={handleChange}
        className={classes.item}
        label="Place"
        ptsPlaceID={ptsPlaceID}
      />
    );
  };

  const renderPlaceType = () => {
    return (
      <Autocomplete2
        options={PlaceTypes}
        className={classes.item}
        onChange={(ev, val) => setPlaceType(val)}
        value={PlaceType}
        label="Place Type"
      />
    );
  };

  const renderDefaultCustodyRecord = () => {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={DefaultCustodyRecord}
              onChange={() => setDefaultCustodyRecord(!DefaultCustodyRecord)}
              color="primary"
            />
          }
          label="Default Custody Record"
          className={classes.item}
        />
      </div>
    );
  };

  const renderAgencyType = () => {
    return (
      <div>
        <FormDivider title="Agency Type" />
        <FormControlLabel
          control={
            <Checkbox checked={isPolice} onChange={() => setIsPolice(!isPolice)} color="primary" />
          }
          label="Police"
          className={classes.item}
        />
        <FormControlLabel
          control={<Checkbox checked={isEms} onChange={() => setIsEms(!isEms)} color="primary" />}
          label="EMS"
          className={classes.item}
        />
        <FormControlLabel
          control={
            <Checkbox checked={isFire} onChange={() => setIsFire(!isFire)} color="primary" />
          }
          label="Fire"
          className={classes.item}
        />
        <ImageUpload
          setImage={setImage}
          image={image}
          setImageData={setImageData}
          setNewImg={setNewImg}
        />
      </div>
    );
  };

  const renderSaveBtn = () => {
    return (
      <div className="text-right pb-3">
        <Button
          // onClick={mode === 'edit' ? handleSaveClick : handleSaveAddClick}
          onClick={handleSaveClick}
          variant="contained"
          disabled={
            mode === 'edit'
              ? !AgencyID || !Description || !Place
              : !AgencyID || !Description || (!ptsPlaceID && !PlaceName)
          }
          color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  const handleIconOptionsClick = event => {
    setFilterText('');
    setAnchorEl(event.currentTarget);
  };

  const handleIconOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (ev, idx) => {
    setSelectedIconIndex(idx);
    setAnchorEl(null);
  };
  const renderIcons = () => {
    const MenuIcon = options[selectedIconIndex];
    return (
      <div className="pl-2" style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">Icon: </Typography>
        <Fab className="ml-2 mr-2" size="small" color="primary" onClick={handleIconOptionsClick}>
          <MenuIcon />
        </Fab>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleIconOptionsClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 10,
              width: '48ch',
            },
          }}>
          <Grid container>
            {Object.entries(options)
              .filter(option => {
                const iconName = option[1].name;
                return iconName.toLowerCase().includes(filterText.toLowerCase());
              })
              .map(([key, Icon]) => (
                <span className="m-3" key={key} onClick={event => handleMenuItemClick(event, key)}>
                  <Fab size="small" color="primary" className="p-1">
                    <Icon style={{ color: 'white' }} />
                  </Fab>
                </span>
              ))}
          </Grid>
        </Menu>
      </div>
    );
  };

  const renderIncidentReportVersion = () => {
    return (
      <Autocomplete2
        options={codeIncidentReportVersions}
        className={classes.field}
        onChange={(ev, val) => setIncidentReportVersion(val)}
        value={IncidentReportVersion}
        label="Incident Report Version"
      />
    );
  };

  return (
    <div>
      {renderSaveBtn()}
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderAgencyID()}
            {renderDescription()}
            {renderIcons()}
          </RowInner>
          <RowInner>
            {renderIncidentReportVersion()}
            {renderDefaultCustodyRecord()}
          </RowInner>

          {renderAgencyType()}
        </ColCard>
        {/* {mode === 'edit' && ( */}
        <ColCard minWidth={500}>
          {renderPlace()}
          {renderPlaceName()}
          {renderPlaceOri()}
          {renderPlaceDeptID()}
          {renderPlaceType()}
        </ColCard>
        {/* )} */}
      </Row>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
  notifyDataUpdate,
  showEditAgency,
  closeEditAgency,
})(AgencyTab);
