import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Fab,
  Menu,
} from '@material-ui/core';
import { Alert, Snackbar } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, Grid, TextField, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { getService } from 'reducers/service';
import {
  formatCommonCodes,
  formatPartyRelationShip,
  formatCities,
  formatCodeDispositions,
  formatCodeZonesTypes,
  formatCodeUnitActions,
  formatCodeVehicleMakeModels,
  formatCodeZones,
  formatTagTypes,
  formatStatuteCodes,
  formatIncidentEventTypeCodes,
  formatEvidenceStorageSublocationValues,
} from 'Settings/LookupCodes/CodeObjectFormatter';

import { SketchPicker } from 'react-color';
import services from 'Settings/LookupCodes/serviceNames';
import TagTypesIcon from 'components/TagTypesIcon';
import RenderStatutes from './RenderStatutes';
import { useSelector } from 'react-redux';
import { checkJsonValidityAndParse } from 'utils/functions';
import EventTypeIcons from 'components/EventTypeIcons';
import Dictionary from 'components/Dictionary';
import RenderEvidenceStorageLocation from './RenderEvidenceStorageLocation';

const styles = makeStyles(theme => ({
  fieldsetWrapperCitationParty: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '15px 15px 15px',
    marginTop: '25px',
  },
  fieldsetTitleCitationParty: {
    position: 'absolute',
    marginTop: '-28px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 8px',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
    top: '-320px',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
}));
const DEFAULT_COLOR = '#dedfe3';
const obj = {
  Code: '',
  Description: '',
  Category: '',
  IsDefault: false,
  IsActive: true,
  Code2: '',
  Description2: '',
  Code3: '',
  Description3: '',
  Code4: '',
  Description4: '',
  Entities: null,
  EmployeeRelated: false,
  AllowMissingPerson: false,
  Order: null,
  CityDescription: '',
  Zone1Type: '',
  Zone2Type: '',
  Zone3Type: '',
  Zone4Type: '',
  Zone5Type: '',
  WaitTime: null,
  ModelCode: '',
  MakeDescription: '',
  ModelDescription: '',
  Color: DEFAULT_COLOR,
  State: null,
  ParentCode: null,
};
const prioritiesOption = [
  { value: 0, label: 'Low' },
  { value: 1, label: 'High' },
];
const citationDictionaryServices = [
  'codepavementpypes',
  'codeweather',
  'codesearchtypes',
  'codecontrabandtype',
  'codearresttype',
  'codeconvictionstatus',
  'codewarningtypes',
  'codeforceused',
  'codehighwaytypes',
  'codelighting',
  'codetraffictypes',
  'codepavementconditions',
];
export const DropdownForCategory = tableName => {
  const allowedService = [
    'codeUnitActions',
    'codeColors',
    'codeIBRStatus',
    'codeIBRExceptionalClearance',
    'codePropertyLossType',
    'codeVictimCategory',
    'codeDrugMeasurements',
    'codeLocationCategories',
    'codeBiasMotivations',
    'codeOffenseFactor',
    'codeForceCategory',
    'codeCriminalActivities',
    'codeAggAssaultType',
    'codeInjuryCategory',
    'codePartyRelationship',
    'codeRace',
    'codeSex',
    'codePropertyCategory',
    'codeSubstanceCategory',
    'codeArresteeArmed',
    'codeVictimSubjectRelationship',
  ];
  return allowedService.includes(tableName);
};
export default function LookUpCodesAddEditDialog(props) {
  const classes = styles();
  const {
    dictionary,
    title,
    open,
    closeDialog,
    mode,
    table,
    data,
    currentService,
    codeCategory,
    aditionalData,
  } = props;
  const { UNIT_ACTIONS } = services;
  const [values, setValues] = useState(obj);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  //const [color, setColor] = useState(DEFAULT_COLOR);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [priority, setPriority] = useState({ label: 'Low', value: 0 });
  const { CategoryTypes } = dictionary;
  const categoryList = useMemo(() => {
    if (!CategoryTypes || CategoryTypes.length == 0) return [];
    const fitlerList = CategoryTypes.filter(item => {
      return table
        ? item.CategoryKey?.toLowerCase() === table?.toLowerCase()
        : item.CategoryKey?.toLowerCase() === currentService?.toLowerCase();
    });
    return fitlerList;
  }, [dictionary, CategoryTypes, table, currentService]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openTagIconMenu = Boolean(anchorEl);
  const openIncidentEventTypeIconMenu = Boolean(anchorEl);
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const user = useSelector(state => state.user);
  const userName = user?.userData?.user?.Username || '';
  const [entitiesCAD, setEntitiesCAD] = useState(false);
  const [entitiesRMS, setEntitiesRMS] = useState(false);
  const [entitiesCitation, setEntitiesCitation] = useState(false);
  const [entitiesWarrants, setEntitiesWarrants] = useState(false);
  const [entitiesEvidence, setEntitiesEvidence] = useState(false);
  const [entitiesActionReport, setEntitiesActionReport] = useState(false);
  const [cadEventTypes, setCadEventTypes] = useState([]);
  const [categoryCodes, setCategoryCodes] = useState([]);
  const [citationDictionaries, setCitationDictionariesEntities] = useState({});
  /** useEffect */
  useEffect(() => {
    const codeTable = async serviceName => {
      const service = getService(serviceName);
      const codes = await service.find({ query: { IsDeleted: false, $limit: 0 } });
      setCategoryCodes(codes.data);
    };

    if (codeCategory) codeTable(codeCategory).catch(error => console.log('error: ', error));
  }, [codeCategory]);

  /* handler functions -> start */
  const handleCloseAlert = () => setOpenAlert(false);
  const handleChange = (key, value) => {
    setValues(prevState => ({ ...prevState, [key]: value }));
  };
  const handleSubmit = async () => {
    if (mode === 'add') {
      try {
        const isAddedSuccessful = await handleAddCodes();
        if (!isAddedSuccessful) return;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      try {
        await handleUpdateCodes();
      } catch (error) {
        console.log('update error : ', error);
      }
    }
    closeDialog();
  };
  const handleCitationDictionariesEntityChange = (name, value) => {
    setCitationDictionariesEntities(prevValue => ({
      ...prevValue,
      [name]: value,
    }));
  };
  /* handler functions -> end */

  /* APi Calls -> start */
  const checkDuplication = async query => {
    const service = getService(currentService);
    const isAlreadyExist = await service.find({ query });
    if (isAlreadyExist.data.length) {
      setOpenAlert(true);
      return true;
    }
    return false;
  };
  const handleAddCodes = async () => {
    let response;
    let isExists = false;
    const service = getService(currentService);
    switch (currentService) {
      case 'codepartyrelationship':
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;
        const entites = getFormatedEntities();
        response = await service.create(formatPartyRelationShip({ ...values, Entities: entites }));
        break;
      case 'code-dispositions':
        isExists = await checkDuplication({
          AgencyID: values.AgencyID,
          Code: values.Code,
          IsDeleted: false,
        });
        if (isExists) return false;
        response = await service.create(formatCodeDispositions(values));
        break;
      case 'codecities':
        response = await service.create(formatCities(values));
        break;
      case 'codezonetypes':
        /* AgencyID */
        isExists = await checkDuplication({ AgencyID: values.AgencyID, IsDeleted: false });
        if (isExists) return false;
        response = await service.create(formatCodeZonesTypes(values));
        break;
      case UNIT_ACTIONS:
        // isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        // if (isExists) return false;
        response = await service.create(formatCodeUnitActions(values));
        break;
      case 'code-vehicle-make-models':
        isExists = await checkDuplication({
          MakeCode: values.MakeCode,
          ModelCode: values.ModelCode,
          IsDeleted: false,
        });
        if (isExists) return false;
        response = await service.create(formatCodeVehicleMakeModels(values));
        break;
      case 'codetagtypes':
        values['Priority'] = priority?.value || 0;
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;
        response = await service.create(formatTagTypes(values));
        break;
      case 'settings-ptsStatutes':
        const formatData = formatStatuteCodes(values, userName);
        response = await service.create(formatData);
        break;
      case 'codeincidenttypes':
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;

        response = await service.create(formatIncidentEventTypeCodes(values));
        break;
      case 'codeevidencestoragesublocation':
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;
        response = await service.create(formatEvidenceStorageSublocationValues(values));
        break;
      case 'codearresttype':
      case 'codecontrabandtype':
      case 'codeconvictionstatus':
      case 'codeforceused':
      case 'codepavementtypes':
      case 'codesearchtypes':
      case 'codewarningtypes':
      case 'codeweather':
      case 'codepavementconditions':
      case 'codetraffictypes':
      case 'codelighting':
      case 'codehighwaytypes':
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;
        const citationEntites = getFormatedEntitiesForCitation();

        response = await service.create({
          ...formatCommonCodes(values),
          Entities: citationEntites,
        });

        break;
      default:
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;
        response = await service.create(formatCommonCodes(values));
        break;
    }
    return true;
  };
  const handleUpdateCodes = async () => {
    const service = getService(currentService);
    switch (currentService) {
      case 'codepartyrelationship': {
        const entites = getFormatedEntities();

        await service.patch(values.Code, formatPartyRelationShip({ ...values, Entities: entites }));
        break;
      }
      case 'codecities':
        await service.patch(values.ptsCityID, formatCities(values));
        break;
      case 'code-dispositions':
        await service.update(
          { AgencyID: values.AgencyID, Code: values.Code },
          formatCodeDispositions(values)
        );
        break;
      case 'codezonetypes':
        await service.patch(values.AgencyID, formatCodeZonesTypes(values));
        break;
      case UNIT_ACTIONS:
        await service.patch(values.Code, formatCodeUnitActions(values));
        break;
      case 'code-vehicle-make-models':
        await service.update(
          { MakeCode: values.MakeCode, ModelCode: values.ModelCode },
          formatCodeVehicleMakeModels(values)
        );
        break;
      case 'codezones':
        await service.patch(values.ZoneCode, formatCodeZones(values));
        break;
      case 'codetagtypes':
        values['Priority'] = priority?.value || 0;
        await service.patch(values.Code, formatTagTypes(values));
        break;
      case 'settings-ptsStatutes':
        const formatData = formatStatuteCodes(values, userName);
        await service.patch(values.ptsStatuteID, formatData);
        break;
      case 'codeincidenttypes':
        await service.patch(values.Code, formatIncidentEventTypeCodes(values));
        break;
      case 'codeevidencestoragesublocation':
        await service.patch(values.Code, formatEvidenceStorageSublocationValues(values));
        break;
      case 'codearresttype':
      case 'codecontrabandtype':
      case 'codeconvictionstatus':
      case 'codeforceused':
      case 'codepavementtypes':
      case 'codesearchtypes':
      case 'codewarningtypes':
      case 'codeweather':
      case 'codepavementconditions':
      case 'codetraffictypes':
      case 'codelighting':
      case 'codehighwaytypes':
        const citationEntites = getFormatedEntitiesForCitation();

        await service.patch(values.Code, {
          ...formatCommonCodes(values),
          Entities: citationEntites,
        });
        break;
      default:
        await service.patch(values.Code, formatCommonCodes(values));
        break;
    }
  };

  const getFormatedEntities = () => {
    const entitiesList = [];
    if (entitiesCAD) entitiesList.push('Cad');
    if (entitiesRMS) entitiesList.push('Rms');
    if (entitiesCitation) entitiesList.push('Citation');
    if (entitiesWarrants) entitiesList.push('Warrants');
    if (entitiesEvidence) entitiesList.push('Evidence');
    if (entitiesActionReport) entitiesList.push('ActionReport');
    return entitiesList.length > 0 ? JSON.stringify(entitiesList) : null;
  };
  const getFormatedEntitiesForCitation = () => {
    const entitiesList = [];
    Object.entries(citationDictionaries || {}).forEach(([key, val]) => {
      if (val) {
        entitiesList.push(key);
      }
    });
    return entitiesList.length > 0 ? JSON.stringify(entitiesList) : null;
  };
  const fetchCadEventTypeCodes = async () => {
    const service = getService('codecadtypes');
    const data = await service.find({
      query: {
        IsDeleted: false,

        $limit: 0,
        $sort: {
          Code: 1,
        },
      },
    });

    return data.data;
  };

  /* Api Calls -> end */

  /* useEffect -> start */

  useEffect(() => {
    const fetchData = async () => {
      const cadEventTypesData = await fetchCadEventTypeCodes();
      setCadEventTypes(cadEventTypesData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (mode === 'edit') {
      setValues({ ...data, Color: data?.Color || DEFAULT_COLOR });

      if (data?.Entities) {
        const parsedEntities = checkJsonValidityAndParse(data.Entities);

        if (parsedEntities && Array.isArray(parsedEntities) && parsedEntities.length > 0) {
          if (citationDictionaryServices.includes(currentService)) {
            const newObj = {};
            parsedEntities.forEach(item => {
              if (item === 'citation') {
                newObj['citation'] = true;
              } else if (item === 'global') {
                newObj['global'] = true;
              }
            });
            setCitationDictionariesEntities(newObj);
          } else {
            parsedEntities.forEach(item => {
              if (item === 'Rms') {
                setEntitiesRMS(true);
              } else if (item === 'Cad') {
                setEntitiesCAD(true);
              } else if (item === 'Citation') {
                setEntitiesCitation(true);
              } else if (item === 'Warrants') {
                setEntitiesWarrants(true);
              } else if (item === 'Evidence') {
                setEntitiesEvidence(true);
              } else if (item === 'ActionReport') {
                setEntitiesActionReport(true);
              }
            });
          }
        }
      } else {
        setCitationDictionariesEntities({});
      }
      if (data?.Priority) {
        const selectedPriority = prioritiesOption.find(item => item.value == data.Priority);
        setPriority(selectedPriority || { label: 'Low', value: 0 });
      } else {
        setPriority({ label: 'Low', value: 0 });
      }
    } else {
      let newObj = { ...obj };
      if (
        currentService === 'codeevidencestoragesublocation' &&
        aditionalData?.storageSublocationParentCode
      ) {
        newObj = { ...newObj, ParentCode: aditionalData.storageSublocationParentCode };
      }
      if (currentService === 'settings-unit-actions') {
        newObj = { ...newObj, Category: 'None' };
      }
      if (citationDictionaryServices.includes(currentService)) {
        setCitationDictionariesEntities({ citation: true });
      }

      setValues(newObj);
      setEntitiesCAD(false);
      setEntitiesCitation(false);
      setEntitiesRMS(false);
      setEntitiesWarrants(false);
      setEntitiesEvidence(false);
      setEntitiesActionReport(false);
    }
  }, [mode, data]);

  useEffect(() => {
    if (['code-dispositions', 'codezonetypes', 'codezones'].includes(currentService)) {
      const fetchAgencies = async () => {
        const service = getService('ptsagencies');
        const response = await service.find({ query: { IsDeleted: false } });
        const newArray = response?.data.map(el => el?.AgencyID);
        setAgencyOptions(newArray);
        //console.log('response agencies ', response);
      };
      fetchAgencies();
    } else if (currentService === 'code-vehicle-make-models') {
      const fetchAgencies = async () => {
        const service = getService('codevehiclemakes');
        const response = await service.find({ query: { IsDeleted: false } });
        const newArray = response?.data.map(el => el?.Code);
        setMakeOptions(newArray);
        //console.log('response agencies ', response);
      };
      fetchAgencies();
    }
  }, [currentService]);

  /* useEffect -> end */

  /* Render Functions -> start */
  const commonForm = () => (
    <>
      {currentService !== 'codezonetypes' && (
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          {currentService !== 'codezones' && (
            <Grid item xs={6} style={{ width: '100%' }}>
              <Box style={{ marginLeft: 10 }}>
                <TextField
                  //fullWidth
                  disabled={mode === 'edit' && currentService !== 'code-vehicle-make-models'}
                  style={{ width: '100%' }}
                  type={'text'}
                  value={values.Code}
                  label={'Code/Description'}
                  //error={error}
                  variant={'outlined'}
                  onChange={e => handleChange('Code', e.target.value)}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description}
                //onChange={handleChange}
                onChange={e => handleChange('Description', e.target.value)}
                helperText={''}
                label={'Description'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code2}
              label={'Code 2/Description 2'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Code2', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description2}
              //onChange={handleChange}
              onChange={e => handleChange('Description2', e.target.value)}
              helperText={''}
              label={'Description 2'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code3}
              label={'Code 3/ Description 3'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Code3', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description3}
              //onChange={handleChange}
              onChange={e => handleChange('Description3', e.target.value)}
              helperText={''}
              label={'Description 3'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code4}
              label={'Code 4/ Description 4'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Code4', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description4}
              //onChange={handleChange}
              onChange={e => handleChange('Description4', e.target.value)}
              helperText={''}
              label={'Description 4'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            {codeCategory === 'codeevidencestoragelocation' ? (
              <Autocomplete
                id="category-cOdes"
                size="small"
                autoHighlight
                autoSelect
                value={{ Code: values.ParentCode || '' }}
                onChange={(event, newValue) => {
                  handleChange('ParentCode', newValue?.Code || '');
                }}
                options={categoryCodes || []}
                getOptionLabel={option => option.Code || ''}
                getOptionSelected={(option, value) =>
                  (option.Code || '').toUpperCase() === (value.Code || '').toUpperCase()
                }
                style={{ width: '100%' }}
                disabled={mode === 'add'}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Storage Location"
                    error={values?.ParentCode ? false : true}
                    helperText={values?.ParentCode ? '' : 'Location is required'}
                  />
                )}
              />
            ) : (
              <Autocomplete
                id="category-cOdes"
                size="small"
                autoHighlight
                autoSelect
                value={{ CategoryCode: values.Category || '' }}
                onChange={(event, newValue) => {
                  handleChange('Category', newValue?.CategoryCode || '');
                }}
                options={categoryList || []}
                getOptionLabel={option => option.CategoryCode || ''}
                getOptionSelected={(option, value) =>
                  (option.CategoryCode || '').toUpperCase() ===
                  (value.CategoryCode || '').toUpperCase()
                }
                style={{ width: '100%' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Category"
                    error={
                      currentService === 'settings-unit-actions'
                        ? values?.Category
                          ? false
                          : true
                        : false
                    }
                    helperText={
                      currentService === 'settings-unit-actions'
                        ? values?.Category
                          ? ''
                          : 'Category is required'
                        : ''
                    }
                  />
                )}
              />
            )}
          </Box>
        </Grid>
        {currentService === 'codealerttypes' && (
          <Grid item xs={2} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <Box style={{ marginLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.Flags?.trim() == 'enable'}
                      onChange={e => handleChange('Flags', e.target.checked ? 'enable' : 'disable')}
                    />
                  }
                  label="Enable Notification"
                />
              </Box>
            </Box>
          </Grid>
        )}

        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsDefault}
                  onChange={e => handleChange('IsDefault', e.target.checked)}
                />
              }
              label="Default"
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsActive}
                  onChange={e => handleChange('IsActive', e.target.checked)}
                />
              }
              label="Active"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const incidentEventTypeForm = () => {
    const MenuIcon = EventTypeIcons[values.Icon ? parseInt(values.Icon) : 0];
    let cadEventTypesOptions = cadEventTypes || [];
    if (values.Category) {
      const filterOptions = cadEventTypes.filter(item => item.Code !== values.Category);
      const selectedOption = cadEventTypes.find(item => item.Code === values.Category);
      if (selectedOption) {
        cadEventTypesOptions = [selectedOption, ...filterOptions];
      }
    }

    return (
      <>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                disabled={mode === 'edit'}
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code}
                label={'Code/Event Type'}
                error={values?.Code?.length > 10 ? true : false}
                helperText={values?.Code?.length > 10 ? '* Code should be 10 characters max' : ''}
                variant={'outlined'}
                onChange={e => handleChange('Code', e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description}
                //onChange={handleChange}
                onChange={e => handleChange('Description', e.target.value)}
                helperText={''}
                label={'Description'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <Autocomplete
                id="category-cOdes"
                size="small"
                autoHighlight
                autoSelect
                value={{ Code: values.Category || '' }}
                onChange={(event, newValue) => {
                  handleChange('Category', newValue?.Code || '');
                }}
                options={cadEventTypesOptions || []}
                getOptionLabel={option => option.Code || ''}
                renderOption={option => (
                  <div className={classes.option}>
                    <span>{option.Code || ''}</span>
                    <em>{option.Description || ''}</em>
                  </div>
                )}
                style={{ width: '100%' }}
                renderInput={params => (
                  <TextField {...params} variant="outlined" label="CAD Event Type" />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsDefault}
                    onChange={e => handleChange('IsDefault', e.target.checked)}
                  />
                }
                label="Default"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsActive}
                    onChange={e => handleChange('IsActive', e.target.checked)}
                  />
                }
                label="Active"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Fab
              className="ml-2 mr-2"
              size="small"
              color="primary"
              onClick={handleIconOptionsClick}>
              <MenuIcon />
            </Fab>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openIncidentEventTypeIconMenu}
              onClose={handleIconOptionsClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 10,
                  width: '48ch',
                },
              }}>
              <Grid container>
                {Object.entries(EventTypeIcons)
                  // .filter(option => {
                  //   const iconName = option[1].name;
                  //   return iconName.toLowerCase().includes(filterText.toLowerCase());
                  // })
                  .map(([key, Icon]) => (
                    <span
                      className="m-3"
                      key={key}
                      onClick={event => handleMenuItemClick(event, key)}>
                      <Fab size="small" color="primary">
                        <Icon color="secondary" style={{ width: '30px', height: '30px' }} />
                      </Fab>
                    </span>
                  ))}
              </Grid>
            </Menu>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code2}
                label={'Code 2/Description 2'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code2', e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description2}
                //onChange={handleChange}
                onChange={e => handleChange('Description2', e.target.value)}
                helperText={''}
                label={'Description 2'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code3}
                label={'Code 3/ Description 3'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code3', e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description3}
                //onChange={handleChange}
                onChange={e => handleChange('Description3', e.target.value)}
                helperText={''}
                label={'Description 3'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code4}
                label={'Code 4/ Description 4'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code4', e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description4}
                //onChange={handleChange}
                onChange={e => handleChange('Description4', e.target.value)}
                helperText={''}
                label={'Description 4'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const codePartyRelationShipForm = () => (
    <>
      {commonForm()}
      <Grid container item xs={12} style={{ marginTop: 10 }} alignItems="center">
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'number'}
              value={values.Order}
              //onChange={handleChange}
              onChange={e => handleChange('Order', e.target.value)}
              helperText={''}
              label={'Order'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.EmployeeRelated}
                  onChange={e => handleChange('EmployeeRelated', e.target.checked)}
                />
              }
              label="EmployeeRelated"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.AllowMissingPerson}
                  onChange={e => handleChange('AllowMissingPerson', e.target.checked)}
                />
              }
              label="Allow Missing Person Report"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'number'}
              value={values.PrintOrder}
              //onChange={handleChange}
              onChange={e => handleChange('PrintOrder', e.target.value)}
              helperText={''}
              label={'Print Order'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}>
          <Box style={{ marginLeft: 10, marginTop: '-8px' }}>
            <Typography variant="h6">Entities</Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entitiesCAD || false}
                  onChange={e => setEntitiesCAD(e.target.checked)}
                />
              }
              label="CAD"
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entitiesRMS || false}
                  onChange={e => setEntitiesRMS(e.target.checked)}
                />
              }
              label="Incident"
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entitiesCitation || false}
                  onChange={e => setEntitiesCitation(e.target.checked)}
                />
              }
              label="Citation"
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entitiesWarrants || false}
                  onChange={e => setEntitiesWarrants(e.target.checked)}
                />
              }
              label="Warrants"
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entitiesEvidence || false}
                  onChange={e => setEntitiesEvidence(e.target.checked)}
                />
              }
              label="Evidence"
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entitiesActionReport || false}
                  onChange={e => setEntitiesActionReport(e.target.checked)}
                />
              }
              label="Action Report"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const renderCodeCitaionDictionaries = () => {
    return (
      <>
        {commonForm()}
        <Grid container item xs={12} style={{ marginTop: 10 }} alignItems="center">
          <Grid item xs={1}>
            <Box style={{ marginLeft: 10, marginTop: '-8px' }}>
              <Typography variant="h6">Entities</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={citationDictionaries.citation || false}
                    onChange={e =>
                      handleCitationDictionariesEntityChange('citation', e.target.checked)
                    }
                  />
                }
                label="Citation"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={citationDictionaries.global || false}
                    onChange={e =>
                      handleCitationDictionariesEntityChange('global', e.target.checked)
                    }
                  />
                }
                label="Golbal"
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  const codeCitiesForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={8} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              disabled={mode === 'edit'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.CityDescription}
              label={'City Description'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('CityDescription', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={4} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Dictionary
              options="States"
              onChange={(ev, val) => {
                handleChange('State', val);
              }}
              value={values.State || ''}
              label="State"
              size="large"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description2}
              label={'Description 2'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Description2', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description3}
              //onChange={handleChange}
              onChange={e => handleChange('Description3', e.target.value)}
              helperText={''}
              label={'Description 3'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Category}
              label={'Category'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Category', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsDefault}
                  onChange={e => handleChange('IsDefault', e.target.checked)}
                />
              }
              label="Default"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsActive}
                  onChange={e => handleChange('IsActive', e.target.checked)}
                />
              }
              label="Active"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const codeDispositionForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={agencyOptions}
              value={values.AgencyID}
              renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
              onChange={(event, selection) => handleChange('AgencyID', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.IsReportRequired}
                  onChange={e => handleChange('IsReportRequired', e.target.checked)}
                />
              }
              label="Report Required"
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const codeVehicleMakeModelsForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={makeOptions}
              value={values.MakeCode}
              renderInput={params => <TextField {...params} label="Make" variant="outlined" />}
              onChange={(event, selection) => handleChange('MakeCode', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              disabled={mode === 'edit'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.ModelCode}
              label={'Model Code'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('ModelCode', e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth

              style={{ width: '100%' }}
              type={'text'}
              value={values.MakeDescription}
              label={'Make Description'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('MakeDescription', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth

              style={{ width: '100%' }}
              type={'text'}
              value={values.ModelDescription}
              label={'Model Description'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('ModelDescription', e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const handleIconOptionsClick = event => {
    setAnchorEl(event.currentTarget);
    // setFilterText('');
  };

  const handleIconOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (ev, idx) => {
    setSelectedIconIndex(idx);
    handleChange('Icon', idx);
    setAnchorEl(null);
  };

  const codeTagTypesForm = () => {
    const tagTypeOptions = ['Incident', 'Person'];
    const MenuIcon = TagTypesIcon[values.Icon ? values.Icon : 0];

    return (
      <>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={6} lg={2} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <Autocomplete
                options={tagTypeOptions}
                value={values.Parent}
                renderInput={params => (
                  <TextField {...params} label="Parent Type" variant="outlined" />
                )}
                onChange={(event, selection) => handleChange('Parent', selection)}
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={2} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                disabled={mode === 'edit'}
                style={{ width: '100%' }}
                type={'text'}
                value={values.Code}
                label={'Code/Description'}
                //error={error}
                variant={'outlined'}
                onChange={e => handleChange('Code', e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={6} lg={3} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description}
                //onChange={handleChange}
                onChange={e => handleChange('Description', e.target.value)}
                helperText={''}
                label={'Description'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={2}>
            <Box style={{ marginLeft: 10 }}>
              <Autocomplete
                id="category-codes"
                autoHighlight
                autoSelect
                value={priority}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  setPriority(newValue);
                }}
                options={prioritiesOption}
                getOptionLabel={option => option.label || ''}
                getOptionSelected={(option, value) => option.value === value.value}
                style={{ width: '100%' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Priority"
                    value={params.inputProps.value}
                  />
                )}
              />

              {/* <Autocomplete
                options={[
                  { label: 'Low', value: 0 },
                  { label: 'High', value: 1 },
                ]}
                value={values.Priority}
                getOptionSelected={row => row}
                renderInput={params => (
                  <TextField {...params} label="Priority" variant="outlined" />
                )}
                onChange={(event, selection) => handleChange('Priority', selection)}
              /> */}
            </Box>
          </Grid>
          <Grid item xs={6} lg={1} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10, marginTop: 10 }}>
              <div>
                <Fab
                  className="ml-2 mr-2"
                  size="small"
                  color="primary"
                  onClick={handleIconOptionsClick}>
                  <MenuIcon style={{ width: '30px', height: '30px' }} />
                </Fab>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={openTagIconMenu}
                  onClose={handleIconOptionsClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 10,
                      width: '48ch',
                    },
                  }}>
                  <Grid container>
                    {Object.entries(TagTypesIcon).map(([key, Icon]) => (
                      <span
                        className="m-3"
                        key={key}
                        onClick={event => handleMenuItemClick(event, key)}>
                        <Fab size="small" color="inherit">
                          <Icon color="secondary" style={{ width: '30px', height: '30px' }} />
                        </Fab>
                      </span>
                    ))}
                  </Grid>
                </Menu>
              </div>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsActive}
                    onChange={e => handleChange('IsActive', e.target.checked)}
                  />
                }
                label="Active"
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  const codeZonesForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.ZoneCode}
              //onChange={handleChange}
              onChange={e => handleChange('ZoneCode', e.target.value)}
              helperText={''}
              label={'Zone Code'}
              disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={agencyOptions}
              value={values.AgencyID}
              renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
              onChange={(event, selection) => handleChange('AgencyID', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const codeZoneTypesForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={agencyOptions}
              value={values.AgencyID}
              renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
              onChange={(event, selection) => handleChange('AgencyID', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone1Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone1Type', e.target.value)}
              helperText={''}
              label={'Zone1 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone2Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone2Type', e.target.value)}
              helperText={''}
              label={'Zone2 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone3Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone3Type', e.target.value)}
              helperText={''}
              label={'Zone3 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone4Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone4Type', e.target.value)}
              helperText={''}
              label={'Zone4 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone5Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone5Type', e.target.value)}
              helperText={''}
              label={'Zone5 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const codeUnitActionForm = () => (
    <>
      {commonForm()}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth

              style={{ width: '100%' }}
              type={'number'}
              value={values.WaitTime}
              label={'Wait Time'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('WaitTime', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Fab
              title="Pick a color"
              className="ml-2 mr-2"
              size="small"
              style={{ backgroundColor: values.Color }}
              onClick={() => setDisplayColorPicker(true)}
            />
            {displayColorPicker ? (
              <div className={classes.popover}>
                <div className={classes.cover} onClick={() => setDisplayColorPicker(false)} />
                <SketchPicker
                  color={values.Color || '#ff0000'}
                  onChange={color => handleChange('Color', color.hex)}
                />
              </div>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const renderFunc = () => {
    switch (currentService) {
      case 'codecities':
        return codeCitiesForm();
      case 'codepartyrelationship':
        return codePartyRelationShipForm();
      case 'code-dispositions':
        return codeDispositionForm();
      case 'codezonetypes':
        return codeZoneTypesForm();
      case UNIT_ACTIONS:
        return codeUnitActionForm();
      case 'code-vehicle-make-models':
        return codeVehicleMakeModelsForm();
      case 'codezones':
        return codeZonesForm();
      case 'codetagtypes':
        return codeTagTypesForm();
      case 'settings-ptsStatutes':
        return (
          <RenderStatutes
            mode={mode}
            values={values}
            setValues={setValues}
            handleChange={handleChange}
          />
        );
      case 'codeevidencestoragelocation':
        return (
          <RenderEvidenceStorageLocation
            mode={mode}
            values={values}
            setValues={setValues}
            categoryList={categoryList}
            table={table}
            handleChange={handleChange}
            categoryCodes={categoryList}
          />
        );
      case 'codeincidenttypes':
        return incidentEventTypeForm();
      case 'codearresttype':
      case 'codecontrabandtype':
      case 'codeconvictionstatus':
      case 'codeforceused':
      case 'codepavementtypes':
      case 'codesearchtypes':
      case 'codewarningtypes':
      case 'codeweather':
      case 'codepavementconditions':
      case 'codetraffictypes':
      case 'codelighting':
      case 'codehighwaytypes':
        return renderCodeCitaionDictionaries();
      default:
        return commonForm();
    }
  };
  const displayAlert = () => (
    <Snackbar
      open={openAlert}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={6000}
      onClose={handleCloseAlert}>
      <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: '100%' }}>
        Item Already Exists!!
      </Alert>
    </Snackbar>
  );
  /* Render Functions -> end */

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={'lg'} fullWidth={true}>
      <DialogTitle style={{ background: '#1976d2', color: 'white' }}>
        {<Typography variant="h5">{title}</Typography>}
      </DialogTitle>
      <DialogContent>
        {displayAlert()}
        <div style={{ padding: 10 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>{renderFunc()}</Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1">
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            className="ml-2"
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={
              (currentService === 'codeevidencestoragesublocation' && !values?.ParentCode) ||
              (currentService === 'settings-unit-actions' && !values.Category)
            }>
            {mode === 'edit' ? 'Update' : 'Save'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
