import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { saveGroupBooleans } from '../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { MainPermissionContext } from '.';
import { getReportsForPermission } from 'helper-functions/reportHelper';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    maxWidth: 300,
  },
  checkStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px',
  },
  item: {
    flex: '1 1 250px',
    margin: '5px',
  },
  table: {
    minWidth: 650,
  },
  searchBar: {
    display: 'flex',
    gap: '100px',
  },
  btn: {
    textAlign: 'right',
    marginBottom: '15px',
    marginRight: '5px',
  },
}));

function ReportPermission(props) {
  const classes = useStyles();
  const { type, title } = props;
  const {
    role,
    allComponents = [],
    allGroupComponents = [],
    refreshComponets,
    setRefreshComponent,
  } = useContext(MainPermissionContext);

  const [allCoreBoolComponents, setAllCoreBoolComponents] = useState([]);
  const [currentRoleWithBools, setCurrentRoleWithBools] = useState(null);
  const [existingBoolIds, setExistingBoolIds] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [initialLoad, setInitialLoad] = useState(true);
  const [allReports, setAllReports] = useState();
  const [filteredCoreBoolComponents, setFilteredCoreBoolComponents] = useState([]);
  const [filterText, setFilterText] = useState('');
  useEffect(() => {
    fetchComponents();
  }, [role]);

  useEffect(() => {
    if (allCoreBoolComponents.length > 0) renderInitialBools();
  }, [role, allGroupComponents, allCoreBoolComponents]);

  useEffect(() => {
    let filteredList = [...allCoreBoolComponents];
    if (filterText) {
      filteredList = allCoreBoolComponents.filter(item =>
        item?.ReportName?.toLowerCase().includes(filterText.toLocaleLowerCase())
      );
    }
    setFilteredCoreBoolComponents(filteredList);
  }, [allCoreBoolComponents, filterText]);
  const fetchComponents = async () => {
    props.showSpinner();

    const reports = await getReportsForPermission();
    setAllReports(reports);

    // const attached = await attachPermissions(cores);

    const coreBools = allComponents.filter(c => c.Type === `${type}.bool`);
    const activeReport = [];
    coreBools.forEach(component => {
      const report = reports.find(item => item.ReportID === component.Label);
      if (report) {
        activeReport.push({ ...component, ReportName: report.Description || report.ReportTitle });
      }
    });

    activeReport.sort((r1, r2) => {
      const report1 = r1.ReportName.toLowerCase();
      const report2 = r2.ReportName.toLowerCase();
      return report1 < report2 ? -1 : report1 > report2 ? 1 : 0;
    });
    setAllCoreBoolComponents(activeReport);
    if (initialLoad) {
      props.hideSpinner();
      setInitialLoad(false);
    } else {
      setTimeout(() => {
        props.hideSpinner();
      }, 1000);
    }
  };

  const renderInitialBools = async () => {
    const filtered = allGroupComponents.filter(
      el => el.ptsGroupID === role.ptsGroupID && el.Permissions !== 0
    );

    const modules = [];
    for (const module of allCoreBoolComponents) {
      const found = filtered.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) modules.push(module.ptsComponentID);
    }

    const newRole = { ...role, CoreBools: modules };
    setCurrentRoleWithBools(newRole);

    const existingComponents = allGroupComponents.filter(el => el.ptsGroupID === role.ptsGroupID);
    const existingIds = [];
    for (const module of allCoreBoolComponents) {
      const found = existingComponents.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) existingIds.push(module.ptsComponentID);
    }
    setExistingBoolIds(existingIds);
  };

  const renderBools = module => {
    const handleChecked = () => {
      if (!currentRoleWithBools) return false;
      const currentBoolIds = [...currentRoleWithBools.CoreBools];
      const found = currentBoolIds.find(modId => modId === module.ptsComponentID);
      return Boolean(found);
    };

    const handleChange = () => {
      const newBoolIds = [...currentRoleWithBools.CoreBools];
      const foundIdx = newBoolIds.indexOf(module.ptsComponentID);
      if (foundIdx === -1) newBoolIds.push(module.ptsComponentID);
      else newBoolIds.splice(foundIdx, 1);
      const newRoleWithBools = { ...currentRoleWithBools, CoreBools: newBoolIds };
      setCurrentRoleWithBools(newRoleWithBools);
    };

    const label = `${module.ReportName || ''} - ${module.Label}`;
    return (
      <FormControlLabel
        control={<Checkbox checked={handleChecked()} onChange={handleChange} color="primary" />}
        label={label}
      />
    );
  };

  const handleBoolSaveClick = async () => {
    const data = {
      GroupId: role.ptsGroupID,
      ExistingComponents: existingBoolIds,
      CurrentComponents: currentRoleWithBools.CoreBools,
    };
    try {
      await saveGroupBooleans(data);
      renderInitialBools();
      setRefreshComponent(!refreshComponets);
      props.notify('Permission Updated', 'success');
    } catch (err) {
      props.handleError(err, 'Error Updating Permission');
    }
  };

  const handleClickedAll = currentValue => {
    const coreBoolComponents = [...allCoreBoolComponents];

    const newBoolIds = [];
    if (currentValue) {
      for (const module of coreBoolComponents) {
        newBoolIds.push(module.ptsComponentID);
      }
    }

    const newRoleWithBools = { ...currentRoleWithBools, CoreBools: newBoolIds };
    setCurrentRoleWithBools(newRoleWithBools);
  };

  const renderSaveBtn = () => {
    return (
      <div className={classes.btn}>
        <Button onClick={handleBoolSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  return (
    <div>
      {renderSaveBtn()}
      {/* {renderCore()}
      <Divider className="mt-4 mb-4" /> */}
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <div>
                    <p>Components (ReportID)</p>
                    <div className={classes.searchBar}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedAll}
                            onChange={() => {
                              setCheckedAll(!checkedAll);
                              handleClickedAll(!checkedAll);
                            }}
                            color="primary"
                          />
                        }
                        label="Select All"
                      />
                      <TextField
                        label="Filter"
                        variant="outlined"
                        key="search-text"
                        value={filterText}
                        style={{ minWidth: '450px' }}
                        onChange={e => setFilterText(e.target.value)}
                        size="small"
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCoreBoolComponents.map(component => (
                <TableRow key={component.ptsComponentID}>
                  <TableCell align="left">{renderBools(component)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default connect(null, { handleError, notify, showSpinner, hideSpinner })(ReportPermission);
